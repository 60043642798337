export const ValidPaths = [65];

ValidPaths[0] = [0];
ValidPaths[1] = [2];
ValidPaths[2] = [1, 4, 5];
ValidPaths[3] = [4, 5, 20, 21];
ValidPaths[4] = [2, 3, 11];
ValidPaths[5] = [2, 3, 7];
ValidPaths[6] = [11, 12];
ValidPaths[7] = [5, 23];
ValidPaths[8] = [12, 24];
ValidPaths[9] = [25];
ValidPaths[10] = [26];
ValidPaths[11] = [4, 6];
ValidPaths[12] = [6, 8, 22];
ValidPaths[13] = [20];
ValidPaths[14] = [21];
ValidPaths[15] = [22];
ValidPaths[16] = [23];
ValidPaths[17] = [24];
ValidPaths[18] = [25];
ValidPaths[19] = [26];
ValidPaths[20] = [3, 13, 27];
ValidPaths[21] = [3, 14, 28];
ValidPaths[22] = [12, 15, 31];
ValidPaths[23] = [7, 16, 25, 30];
ValidPaths[24] = [8, 17, 34];
ValidPaths[25] = [9, 18, 23, 32];
ValidPaths[26] = [10, 19, 34];
ValidPaths[27] = [20, 32];
ValidPaths[28] = [21, 29];
ValidPaths[29] = [28, 33];
ValidPaths[30] = [23, 40];
ValidPaths[31] = [22, 35];
ValidPaths[32] = [25, 27, 33, 40];
ValidPaths[33] = [29, 32, 35, 38];
ValidPaths[34] = [24, 26, 35, 36, 37];
ValidPaths[35] = [31, 33, 34, 41, 42];
ValidPaths[36] = [34, 46];
ValidPaths[37] = [34, 41];
ValidPaths[38] = [33, 42];
ValidPaths[39] = [40, 43];
ValidPaths[40] = [30, 32, 39, 45];
ValidPaths[41] = [35, 37, 44, 46];
ValidPaths[42] = [35, 38, 43, 44];
ValidPaths[43] = [39, 42, 47, 49];
ValidPaths[44] = [41, 42, 47, 54];
ValidPaths[45] = [40, 50];
ValidPaths[46] = [36, 41, 48, 55];
ValidPaths[47] = [43, 44, 52, 53];
ValidPaths[48] = [46, 56];
ValidPaths[49] = [43, 51];
ValidPaths[50] = [45];
ValidPaths[51] = [49];
ValidPaths[52] = [47];
ValidPaths[53] = [47];
ValidPaths[54] = [44];
ValidPaths[55] = [46];
ValidPaths[56] = [48];
ValidPaths[57] = [58, 59];
ValidPaths[58] = [57, 60, 62];
ValidPaths[59] = [57, 60, 61];
ValidPaths[60] = [58, 59, 63, 64];
ValidPaths[61] = [59, 63];
ValidPaths[62] = [58, 64];
ValidPaths[63] = [60, 61];
ValidPaths[64] = [60, 62];
