const ABBattleSupportAbi = [
	{
		inputs: [],
		name: 'ABTokenDataContract',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_newSeraphim', type: 'address' },
		],
		name: 'addSERAPHIM',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'bigAuraEffect',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'winner', type: 'address' }],
		name: 'buffLiquidMetalCornu',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'creatorAddress',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: 'angelId', type: 'uint256' }],
		name: 'getAuraCode',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint16', name: 'aura1', type: 'uint16' },
			{ internalType: 'uint16', name: 'aura2', type: 'uint16' },
		],
		name: 'getAuraDifferenceValue',
		outputs: [{ internalType: 'int16', name: '', type: 'int16' }],
		stateMutability: 'pure',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'petId', type: 'uint256' },
			{ internalType: 'uint256', name: 'accSeriesId', type: 'uint256' },
			{ internalType: 'uint16', name: 'monsterRed', type: 'uint16' },
			{ internalType: 'uint16', name: 'monsterBlue', type: 'uint16' },
			{ internalType: 'uint16', name: 'monsterYellow', type: 'uint16' },
			{ internalType: 'uint16', name: 'power', type: 'uint16' },
			{ internalType: 'uint16', name: 'defenseBuff', type: 'uint16' },
		],
		name: 'getAuraEffects',
		outputs: [
			{ internalType: 'uint16', name: 'newPower', type: 'uint16' },
			{ internalType: 'uint16', name: 'newSpeed', type: 'uint16' },
			{ internalType: 'uint16', name: 'newDefenseBuff', type: 'uint16' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: 'exp', type: 'uint256' }],
		name: 'getExpLevelValue',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'pure',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'angelId', type: 'uint256' },
			{ internalType: 'uint256', name: 'accessoryId', type: 'uint256' },
		],
		name: 'getInitialAngelHp',
		outputs: [{ internalType: 'uint256', name: 'hp', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'petId', type: 'uint256' },
			{ internalType: 'uint256', name: 'accSeriesId', type: 'uint256' },
		],
		name: 'getLureEffect',
		outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint8', name: '_id', type: 'uint8' }],
		name: 'getMonster',
		outputs: [
			{ internalType: 'uint16', name: 'hp', type: 'uint16' },
			{ internalType: 'uint16', name: 'power', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraRed', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraYellow', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraBlue', type: 'uint16' },
			{ internalType: 'uint16', name: 'defenseBuff', type: 'uint16' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint16', name: 'angelDefenseBuff', type: 'uint16' },
			{ internalType: 'uint16', name: 'monsterPower', type: 'uint16' },
			{ internalType: 'uint8', name: 'monsterType', type: 'uint8' },
		],
		name: 'getMonsterAttack',
		outputs: [
			{ internalType: 'uint8', name: 'action', type: 'uint8' },
			{ internalType: 'uint16', name: 'resultValue', type: 'uint16' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint16', name: 'maxRandom', type: 'uint16' },
			{ internalType: 'uint16', name: 'min', type: 'uint16' },
			{ internalType: 'address', name: 'privateAddress', type: 'address' },
		],
		name: 'getRandomNumber',
		outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'initMonsters',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint8', name: 'difficulty', type: 'uint8' }],
		name: 'pickMonster',
		outputs: [
			{ internalType: 'uint8', name: 'monsterType', type: 'uint8' },
			{ internalType: 'uint16', name: 'hp', type: 'uint16' },
			{ internalType: 'uint16', name: 'power', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraRed', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraYellow', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraBlue', type: 'uint16' },
			{ internalType: 'uint8', name: 'action', type: 'uint8' },
			{ internalType: 'uint16', name: 'resultValue', type: 'uint16' },
			{ internalType: 'uint16', name: 'defenseBuff', type: 'uint16' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_oldSeraphim', type: 'address' },
		],
		name: 'removeSERAPHIM',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '', type: 'address' }],
		name: 'seraphims',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_ABTokenDataContract',
				type: 'address',
			},
			{ internalType: 'uint8', name: '_smallAuraEffect', type: 'uint8' },
			{ internalType: 'uint8', name: '_bigAuraEffect', type: 'uint8' },
		],
		name: 'setParameters',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'smallAuraEffect',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'totalSeraphims',
		outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint8', name: '_id', type: 'uint8' },
			{ internalType: 'uint16', name: '_hp', type: 'uint16' },
			{ internalType: 'uint16', name: '_power', type: 'uint16' },
			{ internalType: 'uint16', name: '_auraRed', type: 'uint16' },
			{ internalType: 'uint16', name: '_auraYellow', type: 'uint16' },
			{ internalType: 'uint16', name: '_auraBlue', type: 'uint16' },
			{ internalType: 'uint16', name: '_defenseBuff', type: 'uint16' },
		],
		name: 'updateMonster',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
];

export default ABBattleSupportAbi;
