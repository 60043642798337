const EscapeHatchAbi = [
  {
    inputs: [],
    name: 'ABTokenDataContract',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_battleMtnAddress', type: 'address' },
      { internalType: 'uint8', name: 'spot', type: 'uint8' },
    ],
    name: 'escape',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export default EscapeHatchAbi;
