const VsBattleAbi = [
	{
		inputs: [],
		name: 'ABBattleSupportContract',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ABTokenDataContract',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'BattleMtnDataContract',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'HaloTokenContract',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint8', name: 'accessorySeriesId', type: 'uint8' },
			{ internalType: 'uint256', name: 'petId', type: 'uint256' },
		],
		name: 'applyPetAccessory',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint8', name: 'fromSpot', type: 'uint8' },
			{ internalType: 'uint8', name: 'toSpot', type: 'uint8' },
			{ internalType: 'uint256', name: 'angelId', type: 'uint256' },
			{ internalType: 'uint256', name: 'petId', type: 'uint256' },
			{ internalType: 'uint256', name: 'accessoryId', type: 'uint256' },
		],
		name: 'attackSpot',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint8', name: '_action', type: 'uint8' }],
		name: 'battleRound',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address payable', name: '_newOwner', type: 'address' },
		],
		name: 'changeOwner',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'creatorAddress',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
		name: 'getBattleIdForAddress',
		outputs: [{ internalType: 'uint64', name: 'id', type: 'uint64' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'resultsFor', type: 'address' }],
		name: 'getBattleResultsForCaller',
		outputs: [
			{ internalType: 'uint64', name: 'id', type: 'uint64' },
			{ internalType: 'uint8', name: 'round', type: 'uint8' },
			{ internalType: 'uint16', name: 'defenderHp', type: 'uint16' },
			{ internalType: 'uint8', name: 'defenderAction', type: 'uint8' },
			{ internalType: 'uint16', name: 'defenderResultValue', type: 'uint16' },
			{ internalType: 'uint16', name: 'defenderDefenseBuff', type: 'uint16' },
			{ internalType: 'uint16', name: 'attackerHp', type: 'uint16' },
			{ internalType: 'uint8', name: 'attackerAction', type: 'uint8' },
			{ internalType: 'uint16', name: 'attackerResultValue', type: 'uint16' },
			{ internalType: 'uint8', name: 'attackerPetAuraStatus', type: 'uint8' },
			{ internalType: 'uint8', name: 'defenderPetAuraStatus', type: 'uint8' },
			{ internalType: 'uint16', name: 'attackerDefenseBuff', type: 'uint16' },
			{ internalType: 'bool', name: 'attackerFirst', type: 'bool' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
		name: 'getPower',
		outputs: [{ internalType: 'uint16', name: 'power', type: 'uint16' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint16', name: 'maxRandom', type: 'uint16' },
			{ internalType: 'uint16', name: 'min', type: 'uint16' },
			{ internalType: 'address', name: 'privateAddress', type: 'address' },
		],
		name: 'getRandomNumber',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'resultsFor', type: 'address' }],
		name: 'getStaticAttackerStatsForCaller',
		outputs: [
			{ internalType: 'uint16', name: 'power', type: 'uint16' },
			{ internalType: 'uint16', name: 'speed', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraRed', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraYellow', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraBlue', type: 'uint16' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'resultsFor', type: 'address' }],
		name: 'getStaticOpponentStatsForCaller',
		outputs: [
			{ internalType: 'uint256', name: 'angelId', type: 'uint256' },
			{ internalType: 'uint256', name: 'petId', type: 'uint256' },
			{ internalType: 'uint256', name: 'accessoryId', type: 'uint256' },
			{ internalType: 'uint16', name: 'power', type: 'uint16' },
			{ internalType: 'uint16', name: 'speed', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraRed', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraYellow', type: 'uint16' },
			{ internalType: 'uint16', name: 'auraBlue', type: 'uint16' },
			{ internalType: 'uint8', name: 'attackerAura', type: 'uint8' },
			{ internalType: 'uint8', name: 'defenderAura', type: 'uint8' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_ABTokenDataContract',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_ABBattleSupportContract',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_BattleMtnDataContract',
				type: 'address',
			},
			{ internalType: 'address', name: '_HaloTokenContract', type: 'address' },
			{ internalType: 'uint8', name: '_smallAuraEffect', type: 'uint8' },
			{ internalType: 'uint8', name: '_bigAuraEffect', type: 'uint8' },
			{ internalType: 'uint8', name: '_lureEffect', type: 'uint8' },
			{ internalType: 'uint256', name: '_rewardDivisor', type: 'uint256' },
		],
		name: 'setParameters',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'totalBattles',
		outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
		stateMutability: 'view',
		type: 'function',
	},
];
export default VsBattleAbi;
