const ABArenaBattleAbi = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    inputs: [],
    name: 'ABBattleSupportContract',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'ABTokenDataContract',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'HaloTokenContract',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'angelBaseBattleDelay',
    outputs: [{ internalType: 'uint64', name: '', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint64', name: 'battleId', type: 'uint64' }],
    name: 'attack',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint64', name: 'id', type: 'uint64' }],
    name: 'auraBurst',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'baseDefenseBuff',
    outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'bestAngel',
    outputs: [{ internalType: 'uint64', name: '', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address payable', name: '_newOwner', type: 'address' },
    ],
    name: 'changeOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'angelId', type: 'uint256' },
      { internalType: 'uint256', name: 'petId', type: 'uint256' },
      { internalType: 'uint256', name: 'accessoryId', type: 'uint256' },
      { internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'checkBattleParameters',
    outputs: [],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'creatorAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint64', name: 'id', type: 'uint64' }],
    name: 'defend',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'angelId', type: 'uint256' }],
    name: 'getBattleCooldown',
    outputs: [{ internalType: 'uint64', name: '', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
    name: 'getBattleIdForAddress',
    outputs: [{ internalType: 'uint64', name: 'id', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'resultsFor', type: 'address' }],
    name: 'getBattleResultsForCaller',
    outputs: [
      { internalType: 'uint64', name: 'id', type: 'uint64' },
      { internalType: 'uint8', name: 'status', type: 'uint8' },
      { internalType: 'uint16', name: 'monsterHp', type: 'uint16' },
      { internalType: 'uint8', name: 'monsterAction', type: 'uint8' },
      { internalType: 'uint16', name: 'monsterResultValue', type: 'uint16' },
      { internalType: 'uint16', name: 'monsterDefenseBuff', type: 'uint16' },
      { internalType: 'uint16', name: 'angelHp', type: 'uint16' },
      { internalType: 'uint8', name: 'angelAction', type: 'uint8' },
      { internalType: 'uint16', name: 'angelResultValue', type: 'uint16' },
      { internalType: 'uint8', name: 'petAuraStatus', type: 'uint8' },
      { internalType: 'uint16', name: 'angelDefenseBuff', type: 'uint16' },
      { internalType: 'bool', name: 'angelFirst', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getBestAngel',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint64', name: 'id', type: 'uint64' }],
    name: 'getCurrentBattleResults',
    outputs: [
      { internalType: 'uint64', name: 'battleId', type: 'uint64' },
      { internalType: 'uint16', name: 'monsterHp', type: 'uint16' },
      { internalType: 'uint16', name: 'angelHp', type: 'uint16' },
      { internalType: 'uint8', name: 'angelAction', type: 'uint8' },
      { internalType: 'uint16', name: 'angelResultValue', type: 'uint16' },
      { internalType: 'uint8', name: 'monsterAction', type: 'uint8' },
      { internalType: 'uint16', name: 'monsterResultValue', type: 'uint16' },
      { internalType: 'uint8', name: 'status', type: 'uint8' },
      { internalType: 'bool', name: 'angelFirst', type: 'bool' },
      { internalType: 'uint8', name: 'aura', type: 'uint8' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint64', name: 'id', type: 'uint64' }],
    name: 'getMonsterAttack',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint64', name: 'battleId', type: 'uint64' }],
    name: 'getOrder',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'getPower',
    outputs: [{ internalType: 'uint16', name: 'power', type: 'uint16' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint16', name: 'maxRandom', type: 'uint16' },
      { internalType: 'uint16', name: 'min', type: 'uint16' },
      { internalType: 'address', name: 'privateAddress', type: 'address' },
    ],
    name: 'getRandomNumber',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'resultsFor', type: 'address' }],
    name: 'getStaticAngelStatsForCaller',
    outputs: [
      { internalType: 'uint16', name: 'power', type: 'uint16' },
      { internalType: 'uint16', name: 'speed', type: 'uint16' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'resultsFor', type: 'address' }],
    name: 'getStaticAngelTeamStatsForCaller',
    outputs: [
      { internalType: 'uint256', name: 'angelId', type: 'uint256' },
      { internalType: 'uint256', name: 'petId', type: 'uint256' },
      { internalType: 'uint256', name: 'accessoryId', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'resultsFor', type: 'address' }],
    name: 'getStaticMonsterStatsForCaller',
    outputs: [
      { internalType: 'uint8', name: 'monsterType', type: 'uint8' },
      { internalType: 'uint16', name: 'monsterPower', type: 'uint16' },
      { internalType: 'uint16', name: 'monsterAuraRed', type: 'uint16' },
      { internalType: 'uint16', name: 'monsterAuraYellow', type: 'uint16' },
      { internalType: 'uint16', name: 'monsterAuraBlue', type: 'uint16' },
      { internalType: 'uint8', name: 'aura', type: 'uint8' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'petBattleDelay',
    outputs: [{ internalType: 'uint64', name: '', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_ABTokenDataContract',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_ABBattleSupportContract',
        type: 'address',
      },
      { internalType: 'address', name: '_HaloTokenContract', type: 'address' },
      { internalType: 'uint64', name: '_bestAngel', type: 'uint64' },
      { internalType: 'uint64', name: '_petBattleDelay', type: 'uint64' },
      { internalType: 'uint64', name: '_angelBaseBattleDelay', type: 'uint64' },
      { internalType: 'uint256', name: '_rewardDivisor', type: 'uint256' },
      { internalType: 'uint16', name: '_baseDefenseBuff', type: 'uint16' },
    ],
    name: 'setParameters',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'angelId', type: 'uint256' },
      { internalType: 'uint256', name: 'petId', type: 'uint256' },
      { internalType: 'uint256', name: 'accessoryId', type: 'uint256' },
      { internalType: 'uint8', name: 'difficulty', type: 'uint8' },
    ],
    name: 'startBattle',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint64', name: 'id', type: 'uint64' }],
    name: 'summonPet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalBattles',
    outputs: [{ internalType: 'uint64', name: '', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function',
  },
];
export default ABArenaBattleAbi;
