const MedalClaimAbi = [
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'owner',
				type: 'address',
			},
			{ indexed: false, internalType: 'uint64', name: 'Medal', type: 'uint64' },
		],
		name: 'EventMedalSuccessful',
		type: 'event',
	},
	{
		inputs: [],
		name: 'ABBattleSupportContract',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ABTokenDataContract',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'BattleMtnDataContract',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'DeadAddress',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_newSeraphim', type: 'address' },
		],
		name: 'addSERAPHIM',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'medalId', type: 'uint256' },
			{ internalType: 'uint256', name: 'cardId', type: 'uint256' },
		],
		name: 'burnDiamond',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'medalId', type: 'uint256' },
			{ internalType: 'uint256', name: 'cardId', type: 'uint256' },
		],
		name: 'burnGold',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'medalId', type: 'uint256' },
			{ internalType: 'uint256', name: 'cardId', type: 'uint256' },
			{ internalType: 'uint8', name: 'color', type: 'uint8' },
		],
		name: 'burnOrichalcum',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'medalId', type: 'uint256' },
			{ internalType: 'uint256', name: 'cardId', type: 'uint256' },
			{ internalType: 'uint8', name: 'color', type: 'uint8' },
		],
		name: 'burnPlatinum',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'medalId', type: 'uint256' },
			{ internalType: 'uint256', name: 'cardId', type: 'uint256' },
		],
		name: 'burnSilver',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'onePlyId', type: 'uint256' },
			{ internalType: 'uint256', name: 'twoPlyId', type: 'uint256' },
			{ internalType: 'uint256', name: 'cardboardId', type: 'uint256' },
			{ internalType: 'uint256', name: 'bronzeId', type: 'uint256' },
		],
		name: 'burnSimple',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'medalId', type: 'uint256' },
			{ internalType: 'uint256', name: 'cardId', type: 'uint256' },
			{ internalType: 'uint8', name: 'color', type: 'uint8' },
		],
		name: 'burnStupidFluffyPink',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'medalId', type: 'uint256' },
			{ internalType: 'uint256', name: 'cardId', type: 'uint256' },
		],
		name: 'burnTitanium',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'medalId', type: 'uint256' },
			{ internalType: 'uint256', name: 'cardId', type: 'uint256' },
		],
		name: 'burnZeronium',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'cardboardClaimedAngel',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address payable', name: '_newOwner', type: 'address' },
		],
		name: 'changeOwner',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'address', name: 'owner', type: 'address' },
		],
		name: 'checkOwner',
		outputs: [],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'id1', type: 'uint256' },
			{ internalType: 'uint256', name: 'id2', type: 'uint256' },
			{ internalType: 'uint256', name: 'id3', type: 'uint256' },
			{ internalType: 'uint256', name: 'id4', type: 'uint256' },
		],
		name: 'claim1Ply',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'geckoId', type: 'uint256' },
			{ internalType: 'uint256', name: 'parakeetId', type: 'uint256' },
			{ internalType: 'uint256', name: 'catId', type: 'uint256' },
			{ internalType: 'uint256', name: 'horseId', type: 'uint256' },
		],
		name: 'claim2Ply',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'komodoId', type: 'uint256' },
			{ internalType: 'uint256', name: 'falconId', type: 'uint256' },
			{ internalType: 'uint256', name: 'bobcatId', type: 'uint256' },
			{ internalType: 'uint256', name: 'unicornId', type: 'uint256' },
		],
		name: 'claimBronze',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint64', name: 'angelId', type: 'uint64' }],
		name: 'claimCardboard',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'angelId', type: 'uint256' },
			{ internalType: 'uint8', name: 'position', type: 'uint8' },
		],
		name: 'claimDiamond',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: 'angelId', type: 'uint256' }],
		name: 'claimGold',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: 'elementalId', type: 'uint256' }],
		name: 'claimOrichalcum',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'rockDragonId', type: 'uint256' },
			{ internalType: 'uint256', name: 'archId', type: 'uint256' },
			{ internalType: 'uint256', name: 'sabertoothId', type: 'uint256' },
			{ internalType: 'uint256', name: 'pegasusId', type: 'uint256' },
		],
		name: 'claimPlatinum',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint64', name: 'redAngel', type: 'uint64' },
			{ internalType: 'uint64', name: 'greenAngel', type: 'uint64' },
			{ internalType: 'uint64', name: 'purpleAngel', type: 'uint64' },
			{ internalType: 'uint64', name: 'yellowAngel', type: 'uint64' },
		],
		name: 'claimSilver',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'direDragonId', type: 'uint256' },
			{ internalType: 'uint256', name: 'phoenixId', type: 'uint256' },
			{ internalType: 'uint256', name: 'ligerId', type: 'uint256' },
			{ internalType: 'uint256', name: 'alicornId', type: 'uint256' },
		],
		name: 'claimStupidFluffyPink',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: 'angelId', type: 'uint256' }],
		name: 'claimZeronium',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		name: 'claimedbyAddress',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'creatorAddress',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'diamondClaimedAngel',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
		name: 'getCardSeries',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint16', name: 'maxRandom', type: 'uint16' },
			{ internalType: 'uint8', name: 'min', type: 'uint8' },
			{ internalType: 'address', name: 'privateAddress', type: 'address' },
		],
		name: 'getRandomNumber',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'goldClaimedAngel',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'mainClaimedPets',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'onePlyClaimedAngel',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_oldSeraphim', type: 'address' },
		],
		name: 'removeSERAPHIM',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '', type: 'address' }],
		name: 'seraphims',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_ABTokenDataContract',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_ABBattleSupportContract',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_BattleMtnDataContract',
				type: 'address',
			},
		],
		name: 'setDataContacts',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'silverClaimedAngel',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'totalSeraphims',
		outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'zeroniumClaimedAngel',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
];

export default MedalClaimAbi;
